import { useFeature } from 'hooks/useFeature';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { Product } from '__generated__/graphql';

import { StarRating } from './StarRating';

export const ProductListItemRating = (props: { master: Product }) => {
  const t = useTranslate();
  const showRatingsAndReviews = useFeature('RATINGS_AND_REVIEWS');
  const { score } = props.master;
  const {
    staticFeatures: { isPNARegion },
  } = useSiteConfig();
  return score?.rating &&
    score?.amount &&
    showRatingsAndReviews &&
    !isPNARegion ? (
    <div className="flex items-center" data-test-id="plp-star-rating">
      <span className="sr-only">{t('reviewRating')}</span>
      <StarRating rating={score.rating} />
      <span className="ml-1 pl-px text-sm">
        ({score.amount})<span className="sr-only">{t('reviews')}</span>
      </span>
    </div>
  ) : null;
};
