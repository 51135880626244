import { Icon } from '@global-ecom/nitro-uds/elements';

const StarsRatingEmplifi = ({
  averageRating,
  amountOfReviews,
}: {
  averageRating: number;
  amountOfReviews: number;
}) => {
  const convertAverageRatingIntoStarsStatusObject = (averageRating: number) => {
    // NOTE: this way of parsing decimal numbers (1.1, 1.2, x.3, ...)
    //       (here represented as 1, 2, 3, ...) to empty (0),
    //       half-filled (0.5) or full (1) stars was extracted
    //       manually from how TurnTo internally handles stars in
    //       their widgets.
    const parseDecimalToStar = {
      1: 0,
      2: 0,
      3: 0.5,
      4: 0.5,
      5: 0.5,
      6: 0.5,
      7: 0.5,
      8: 1,
      9: 1,
    };

    const numberHasDecimalPlace =
      averageRating - Math.floor(averageRating) !== 0;
    const decimalPartOfNumber = String(averageRating).split('.')[1];
    const showHalfStar = numberHasDecimalPlace
      ? parseDecimalToStar[decimalPartOfNumber] === 0.5
      : false;
    const numberHasDecimalPlaceButShowFullStar =
      numberHasDecimalPlace && parseDecimalToStar[decimalPartOfNumber] === 1;

    return {
      filled:
        Math.trunc(averageRating) +
        (numberHasDecimalPlaceButShowFullStar ? 1 : 0),
      half: showHalfStar ? 1 : 0,
      empty:
        5 -
        Math.trunc(averageRating) +
        (showHalfStar || numberHasDecimalPlaceButShowFullStar ? -1 : 0),
    };
  };

  const starsStatus = convertAverageRatingIntoStarsStatusObject(averageRating);

  return (
    <div className="flex space-x-1 items-center">
      <div className="flex -mb-0.5">
        {Object.keys(starsStatus).map(key =>
          Array.from(Array(starsStatus[key]).keys()).map(number => (
            <Icon
              key={number}
              name={`star-${key as 'filled' | 'half' | 'empty'}`}
              className="h-5 w-5 -mx-0.5"
            />
          ))
        )}
      </div>
      <p>({amountOfReviews})</p>
    </div>
  );
};

export default StarsRatingEmplifi;
